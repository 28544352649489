export const focusableSelector = [
  '[tabindex]:not([tabindex=\'-1\'])',
  'a[href]',
  'button',
  'details',
  'input:not([type=\'hidden\'])',
  'select',
  'textarea',
]
  .map((el) => `${el}:not([disabled])`)
  .join(', ');

/**
 * Queries for focusable elements within an element.
 * @param {Element} node element to query
 * @returns {Element[]} focusable elements
 */
export function queryFocusableElements(node) {
  return Array.from(node.querySelectorAll(focusableSelector)).filter(
    (el) => el.offsetParent,
  );
}
